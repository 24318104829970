/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AvocatImage,
  Dash1,
  Dash2,
  Dash4,
  OrangeImage,
  PineappleImage,
  StawberryImage,
  search_whites,
} from "../EntryFile/imagePath";
import Table from "../EntryFile/datatables";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import RightSideBar from "../components/rightSidebar";
//import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import PropTypes from 'prop-types';

import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../components/Spinner";

import "react-select2-wrapper/css/select2.css";

import AuthUser from "../components/AuthUser";


/* Para o calendario fim */

const DashboardBanco = () => { 

  const { http } = AuthUser();

  const [inputfilter, setInputfilter] = useState(false);


  const [dashboard_info, setDashboardInfo] = useState([]);

  const [permissaoVer, setPermissaoVer] = useState(false);

  const [data1Filter, setData1Filter] = useState('');
  const [data2Filter, setData2Filter] = useState('');

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setData1Filter('');
      setData2Filter('');
    }

  };

  const handleFilterSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append('data1', data1Filter);
      formData.append('data2', data2Filter);

      const response = await http.post('/dashboard/formacao/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setDashboardInfo(response.data);

      console.log(response.data.inscricoes);

      // Atualize a lista de utilizadores após resetar a senha
      //setUtilizador(response.data.formadores);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };

  const [expiredData] = useState([
    {
      id: 1,
      code: "IT001",
      image: OrangeImage,
      productName: "Mão de Obra",
      brandName: "N/D",
      categoryName: "Fruits",
      expiryDate: "65 000",
    },
    {
      id: 2,
      code: "IT002",
      image: PineappleImage,
      productName: "Manutenção",
      brandName: "N/D",
      categoryName: "Fruits",
      expiryDate: "45 000",
    },
    {
      id: 3,
      code: "IT003",
      image: StawberryImage,
      productName: "Insumos",
      brandName: "N/D",
      categoryName: "Fruits",
      expiryDate: "40 000",
    },
    {
      id: 4,
      code: "IT004",
      image: AvocatImage,
      productName: "Equipamentos",
      brandName: "N/D",
      categoryName: "Fruits",
      expiryDate: "300 000",
    },
  ]);


  const expiredProductColumns = [
    {
      title: "SNo",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Custo",
      dataIndex: "code",
      render: (text) => (
        <Link to="#" style={{ fontSize: "14px" }}>
          {text}
        </Link>
      ),
      sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      title: "Descrição",
      dataIndex: "productName",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img">
            <img alt="" src={record.image} />
          </Link>
          <Link to="#" style={{ fontSize: "14px" }}>
            {record.productName}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.productName.length - b.productName.length,
    },
    {
      title: "Categoria",
      dataIndex: "categoryName",
      render: (text) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.categoryName.length - b.categoryName.length,
    },
    {
      title: "Valor Kz",
      dataIndex: "expiryDate",
      render: (text) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.expiryDate.length - b.expiryDate.length,
    },
  ];

  //aqui inicia

  const state10 = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: (dashboard_info?.cursosInscricoes || []).map(cursosInscricoes => cursosInscricoes.designacao.toUpperCase()),
      },
    },
    series: [
      {
        name: "Total inscrições ",
        data: (dashboard_info?.cursosInscricoes || []).map(cursosInscricoes => cursosInscricoes.quantidadeInscricoesNoPeriodo),
      },
    ],
  };

  // Extrai os totais de associações e os dias do objeto retornado pela API
  const totaisAssociacoes = Object.values(dashboard_info?.inscricoes || {});
  const diasDoMes = Object.keys(dashboard_info?.inscricoes || {}).map(key => String(key));

  const state12 = {
    series: [{
      name: 'Totais de Associações',
      data: totaisAssociacoes,
    }],
    options: {
      chart: {
        height: 200,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",  // Mudança aqui para indicar que as categorias são strings
        categories: diasDoMes,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };



  // Extrai os totais de associações e os dias do objeto retornado pela API
  const entradas = Object.values(dashboard_info?.entradas || {});
  const saidas = Object.values(dashboard_info?.saidas || {});
  const meses = Object.keys(dashboard_info?.entradas || {}).map(key => String(key));

  const state13 = {
    series: [
      {
        name: "Saidas",
        data: saidas,
      },
      {
        name: "Entradas",
        data: entradas,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: meses
      },
      yaxis: {
        title: {
          text: "Kz (kwanzas)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "Kz " + val + "";
          },
        },
      },
    },
  };


  const state6 = {
    series: (dashboard_info?.cursosPagamento || []).map(cursosPagamento => cursosPagamento.totalPagamentoNoPeriodo),
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: (dashboard_info?.cursosPagamento || []).map(cursosPagamento => cursosPagamento.designacao.toUpperCase()),
    },
  };


  function calcularSomaTotalSaidas() {
    if (dashboard_info?.pagamentos?.length === 0) {
      return 0;
    }

    var i = 0, somaTotal = 0;

    for (i = 0; i < dashboard_info?.pagamentos?.length; i++) {
      if (dashboard_info?.pagamentos[i]?.movimento == 2) {
        somaTotal = somaTotal + Number(dashboard_info?.pagamentos[i]?.valor);
      }
    }

    return somaTotal;

  }

  function calcularSomaTotalEntradas() {
    if (dashboard_info?.pagamentos?.length === 0) {
      return 0;
    }

    var i = 0, somaTotal = 0;

    for (i = 0; i < dashboard_info?.pagamentos?.length; i++) {
      if (dashboard_info?.pagamentos[i]?.movimento == 1) {
        somaTotal = somaTotal + Number(dashboard_info?.pagamentos[i]?.valor);
      }
    }

    return somaTotal;

  }

  function calcularSomaTotalFormacoes() {
    if (dashboard_info?.pagamentos?.length === 0) {
      return 0;
    }

    var i = 0, somaTotal = 0;

    for (i = 0; i < dashboard_info?.pagamentos?.length; i++) {
      if (dashboard_info?.pagamentos[i]?.movimento == 1 && dashboard_info?.pagamentos[i]?.natureza == 1) {
        somaTotal = somaTotal + Number(dashboard_info?.pagamentos[i]?.valor);
      }
    }

    return somaTotal;

  }

  //aqui termina


  useEffect(() => {    

    togglefilter(true);

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getFormador()
        await getDashboardInfo();

        setPermissaoVer(dashboard_info?.pagamento_permissao);

      } catch (error) {
        console.error('Erro ao obter dados do dashboard:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();

  },[permissaoVer]);

  const getDashboardInfo = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/dashboard/formacao');

      setDashboardInfo(res.data);


      console.log(res.data);
      console.log("entradas :");
      console.log(res.data.entradas);

      console.log("saidas :");
      console.log(res.data.saidas);


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };



  return (
    <>
      <div className="page-wrapper">
        <Helmet>
          <title>TrainningBusiness System</title>
          <meta name="description" content="Dashboard page" />
        </Helmet>
        <div className="content">
          <div className="row">
            <div className="col-lg-12">


              <div className="card">
                <div className="card-body">

                  {/* /Filter */}
                  <div
                    className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                    id="filter_inputs"
                    style={{ display: inputfilter ? "block" : "none" }}
                  >
                    <div className="card-body pb-0">
                      <div className="row">

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group" title="data 1">
                            <input type="date" className="form-control" placeholder="data1" value={data1Filter}
                              onChange={(e) => setData1Filter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12" title="data 2">
                          <div className="form-group">
                            <input type="date" className="form-control" placeholder="data2" value={data2Filter}
                              onChange={(e) => setData2Filter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              {loading ? <Spinner /> : <img src={search_whites} alt="img" onClick={handleFilterSubmit} />}

                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Filter */}

                </div>
              </div>

            </div>


            {permissaoVer ? <>


              <div className="col-lg-3 col-sm-6 col-12">
                <div className="dash-widget">
                  <div className="dash-widgetimg">
                    <span>
                      <img src={Dash2} alt="img" />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      Kz &nbsp;
                      <span className="counters">
                        <CountUp end={calcularSomaTotalEntradas()} />
                      </span>
                    </h5>
                    <h6>Serviços + formações</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="dash-widget dash1">
                  <div className="dash-widgetimg">
                    <span>
                      <img src={Dash1} alt="img" />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>

                      <span className="counters">
                        <CountUp end={dashboard_info?.inscricoesTotal} />
                      </span>
                    </h5>
                    <h6>Inscrições</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="dash-widget dash2">
                  <div className="dash-widgetimg">
                    <span>
                      <img src={Dash2} alt="img" />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      Kz &nbsp;
                      <span className="counters">
                        <CountUp end={calcularSomaTotalFormacoes()} />
                      </span>
                    </h5>
                    <h6>Formações ( kz )</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="dash-widget dash3">
                  <div className="dash-widgetimg">
                    <span>
                      <img src={Dash4} alt="img" />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      <span className="counters">
                        <CountUp end={calcularSomaTotalSaidas()} />
                      </span>
                    </h5>
                    <h6>Saidas de caixa</h6>
                  </div>
                </div>
              </div>

            </>

              : <></>}

            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count">
                <div className="dash-counts">
                  <h4>{dashboard_info.cursos}</h4>
                  <h5>CURSOS</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="square" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das1">
                <div className="dash-counts">
                  <h4>{dashboard_info.turmas}</h4>
                  <h5>TURMAS</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="list" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das2">
                <div className="dash-counts">
                  <h4>{dashboard_info.formandos}</h4>
                  <h5>FORMANDOS</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="user" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das3">
                <div className="dash-counts">
                  <h4>{dashboard_info.formadores}</h4>
                  <h5>FORMADORES</h5>
                </div>
                <div className="dash-imgs">
                  <FeatherIcon icon="users" />
                </div>
              </div>
            </div>
          </div>


          {/* Começou sementes plantas atualemnte */}


          {/* terminou sementes plantadas atualmente */}


          {/* começou grafico de linha */}
          <div className="row">

            {/* Chart */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5 className="card-title">Inscrições</h5>
                </div>
                <div className="card-body">
                  <div id="s-line-area" />
                  <Chart
                    options={state12.options}
                    series={state12.series}
                    type="area"
                    height={350}
                  />
                </div>
              </div>
            </div>
            {/* /Chart */}


          </div>
          {/* terminou grafico de linha */}



          {permissaoVer ? <>
            {/* Button trigger modal */}
            <div className="row">

              {/* Chart */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                    <div className="card-title">Pagamentos por curso</div>
                  </div>
                  <div className="card-body">
                    <div className="chartjs-wrapper-demo">
                      <Chart
                        options={state6.options}
                        series={state6.series}
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Chart */}


              <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-0">Formandos por curso</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive dataview">
                      <Chart
                        options={state10.options}
                        series={state10.series}
                        type="bar"
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </> : (<></>)
          }


          {permissaoVer ? <>

            {/* inicio do grafico de despesas versos entradas */}
            <div className="row">


              {/* Chart */}
              <div className="col-md-12">
                <div className="card flex-fill">
                  <div className="card-header">
                    <h5 className="card-title">Entradas X Saidas</h5>
                  </div>
                  <div className="card-body">
                    <div id="s-col" />
                    <Chart
                      options={state13.options}
                      series={state13.series}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </div>
              {/* /Chart */}

            </div>
            {/* fim inicio do grafico de despesas versos entradas */}

          </> : (<></>)
          }


          {/* novo inicio */}

          {expiredProductColumns == 2 ? <>

            <div className="row">
              {/* Chart */}
              <div className="col-md-12">

                <div className="card mb-0">
                  <div className="card-body">
                    <h4 className="card-title">Inscrições recentes</h4>
                    <div className="table-responsive dataview">
                      <Table
                        columns={expiredProductColumns}
                        dataSource={expiredData}
                      />
                    </div>
                  </div>
                </div>

              </div>
              {/* /Chart */}

            </div> </> : <></>
          }

          {/* novo fim */}




        </div>
      </div>
      <RightSideBar />

      <ToastContainer />

    </>
  );
};

DashboardBanco.propTypes = {
  permissaoVer: PropTypes.bool.isRequired
};

export default DashboardBanco;